import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { UserIdleService } from 'angular-user-idle';
import { ConfirmationDialogService } from '../pages/utility/confirmdialog/confirmation-dialog-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum ModelTypeEnum {
  Normal = 1,
  CBS_Commission = 2,
  Mogadishu_Local_Government = 3
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastr: ToastrService,
    private userIdle: UserIdleService,
    private router: Router,
    private translate: TranslateService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
  ) {
    this.startWatchingIdleTime();
  }





  // This Method is used to start Wathcing idle Time
  startWatchingIdleTime() {
    if (this.getValueFromLocalStorage('loginInfo') != null) {
      //Start watching for user inactivity
      this.userIdle.startWatching();
      console.log("started Watching Idle Time");
      this.idleTimeActionPerform();
    }
  }

  // This method is used to perform action once it is Idle
  idleTimeActionPerform() {

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('Time is up!');
      if (this.getValueFromLocalStorage('loginInfo') != null) {
        this.clearValueFromLocalStorage();
        this.stopWatchingIdleTime();
      }
    });
  }


  // This Method is used to Stop Wathcing idle Time
  async stopWatchingIdleTime() {
    const OK = await this.translate.get('OK').toPromise();
    const SessionExpired = await this.translate.get('SessionExpired').toPromise();
    this.userIdle.stopWatching();
    await this.closeAction();
    this.confirmationDialogService.confirm('', SessionExpired, OK, '')
      .then((confirmed) => {
        this.router.navigate(['/usermanagement/login']);
      });
  }

  // This Method is used to Reset  idle Time
  restartIdleTime() {
    this.userIdle.resetTimer();
  }

  // Check login Info & Session
  isUserLoggedIn() {
    if (this.getValueFromLocalStorage('loginInfo') == null) {
      this.router.navigate(['/usermanagement/login']);
    } else {
      this.restartIdleTime();
    }
  }

  // TO closing Update PopUp
  async closeAction() {
    if (this.modalService.hasOpenModals) {
      await this.modalService.dismissAll();
    }
  }

  //Update an entry just as you would create a new one with setItem, but with a key that already exists:
  setValueToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  //Read entries with localStorage.getItem:
  getValueFromLocalStorage(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  //Delete an entry with the removeItem method:
  deleteValueFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  //Delete an entry with the removeItem method:
  clearValueFromLocalStorage() {
    localStorage.clear();
  }

  showToastMessage(message: string, messageType: number) {
    const obj: any = {
      closeButton: true,
      timeOut: 3000,
      progressBar: true,
      positionClass: 'toast-top-full-width',
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      }
    }
    if (messageType == 1) {
      this.toastr.success('', message, obj);
    } else {
      this.toastr.error('', message, obj);
    }
  }

  routerNavigationDetection() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //this.mesage = {};
      }
    });
  }



  private readonly sercatKey: string = "8080808080808080";

  encrypt(planText: string): string {
    let _key = CryptoJS.enc.Utf8.parse(this.sercatKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.sercatKey);
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(planText), _key, {
      keySize: 128 / 8,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  decryptUsingAES256(encryptedText: string): string {
    let _key = CryptoJS.enc.Utf8.parse(this.sercatKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.sercatKey);

    let decrypted = CryptoJS.AES.decrypt(
      encryptedText, _key, {
      keySize: 128 / 8,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  // this method is used to check permission for action in individual Page
  checkPermission(permissionCode: string): boolean {
    // get Login Info
    let loginInfo = this.getValueFromLocalStorage('loginInfo');
    if (loginInfo != null && loginInfo.featureIdList != null) {
      loginInfo = loginInfo.featureIdList.replace(/ /g, '');
      if (loginInfo.endsWith(',')) {
        loginInfo = loginInfo.substring(0, loginInfo.length - 1);
      }
      let perArray = loginInfo.split(",");
      if (perArray.lastIndexOf(permissionCode) >= 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  sortByPropertyinASC(property) {
    return function (a, b) {
      if (a[property] > b[property])
        return 1;
      else if (a[property] < b[property])
        return -1;

      return 0;
    }
  }

  sortByPropertyinDESC(property) {
    return function (a, b) {
      if (b[property] > a[property])
        return 1;
      else if (b[property] < a[property])
        return -1;

      return 0;
    }
  }

  // All Role Permission Code
  public readonly permission: any = {
    "AM": {
      "ROLE": {
        CREATE_ROLE: 'CR_RM',
        UPDATE_ROLE: 'UP_RL',
        DELETE_ROLE: 'DL_RL',
        LIST_ROLE: 'LS_RL'

      },
      "DEPARTMENT": {
        LIST_DEPARTMENT: 'LS_DM',
        CREATE_DEPARTMENT: 'CR_DM',
        UPDATE_DEPARTMENT: 'UP_DM',
        DELETE_DEPARTMENT: 'DL_DM'
      },
      "USER": {
        LIST_USER: 'LS_UM',
        CREATE_USER: 'CR_UM',
        UPDATE_USER: 'UP_UM',
        DELETE_USER: 'DL_UM'
      }
    },
    "COA": {
      LIST_COA: 'LS_COA'
    },
    "RFM": {
      CREATE_RFM: 'CR_RFM'
    },
    "EXPENSE": {
      CREATE_EXPENSE: 'CR_EXP'
    },
    "RFR": {
      VIEW_RFR: 'VIEW_RFR'
    },
    "ADJUSTALLOT": {
      ADJ_ALT: 'AJ_EXP'
    },
    "LISTRCP": {
      LIST_RCP: 'LS_RCP'
    },
    "CCP": {
      LIST_CCP: 'LS_CCP',
      APPROVE_CCP: 'AP_CCP'
    },
    "LISTREP": {
      ALLOTMENT_REP: 'AAR_RT',
      REVENUE_REP: 'CABRFAR_RT',
      CABAAE_REP: 'CABAAE_RT',
      TRENDREV_REP: 'TAAR_RT',
      TRENDEXP_REP: 'TAAE_RT'
    },
    "LSALLOT": {
      LS_ALLOT: 'LS_ALLOT'
    },
    "SETTING": {
      MDR_SETTING: 'MDR_STG',
      AAH_SETTING: 'AAH_STG',
      CC_SETTING: 'CC_STG'
    }
  }

  // Digit Pattern
  numberpattern = "[0-9]*";

  // Method is user to check whether variable is object or Not
  isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor == Object;
  }

  // this method is used to check permission for main group & sub group in Header page Only
  mainOrSubGroupCheckPermission(mainGroupName, subGroupName): boolean {
    // get Login Info
    let loginInfo = this.getValueFromLocalStorage('loginInfo');
    let outerJson = subGroupName == '' ? this.permission[mainGroupName] : this.permission[mainGroupName][subGroupName]
    if (this.isObject(outerJson)) {
      for (var inner in outerJson) {
        var innerJson = outerJson[inner];
        if (this.isObject(innerJson)) {
          for (var action in innerJson) {
            var actionJson = innerJson[action];
            let per = this.checkPermission(actionJson)
            if (per) {
              return per;
            }
          }
        } else {
          let per = this.checkPermission(innerJson)
          if (per) {
            return per;
          }
        }
      }
    }
    return false;
  }


  createGroupSubGroupForm(result: any[], mainProperty: string, subProperity: string = ''): any[] {
    const mainGroupedCollection = result.reduce((previous, current) => {
      if (!previous[current[mainProperty]]) {
        previous[current[mainProperty]] = [current];
      } else {
        previous[current[mainProperty]].push(current);
      }
      return previous;
    }, {});
    return Object.keys(mainGroupedCollection).map(key => (
      {
        key: key,
        keyName: mainGroupedCollection[key].length > 0 ? mainGroupedCollection[key][0][subProperity] ? mainGroupedCollection[key][0][subProperity] : null : null,
        value: mainGroupedCollection[key],
      }
    ));
  }

  mapAndJoinWithComma(arr, key) {
    if (arr == '' || arr == null)
      return '';
    return arr.map(function (o) {
      return o[key];
    }).join();
  }

  // allow only number
  allowNumeric(event) {
    var k;
    k = event.charCode;
    console.log(k);
    return ((k >= 48 && k <= 57) || k == 45 || k == 46);
  }

  showHideLinkByStsId(stausId, moduleName): boolean {
    let flag: boolean = false;
    switch (moduleName) {
      case 'RevCRAnlPlan':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevMonthList':
        flag = [this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevCRMonthP':
        flag = [this.approvedStatusId].includes(stausId);
        break;
      case 'RevAnlClone':
        flag = [this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevCRModel':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevGenCashPln':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevSaveForecast':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevTransAdj':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'RevStatusRevertToDraft':
        flag = [this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId,this.submittedStatusId].includes(stausId);
        break;


      case 'ExpMonthList':
        flag = [this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'ExpCRMonthP':
        flag = [this.approvedStatusId].includes(stausId);
        break;
      case 'ExpCRAnlPlan':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'ExpCRModel':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'ExpGenCashPln':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'ExpSaveForecast':
        flag = ![this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId].includes(stausId);
        break;
      case 'ExpStatusRevertToDraft':
        flag = [this.planGeneratedStatusId, this.approvedStatusId, this.archivedStatusId,this.submittedStatusId].includes(stausId);
        break;

      default:
        break;
    }

    return flag;


  }

  // Power BI Branch Filter Schema url
  public readonly powerbiFilterSchemaURL: string = 'https://powerbi.com/product/schema#basic';

  d = new Date();
  public filterMonth: number = (this.d.getMonth() + 1);
  public filterDate: number = 25;


  public titleName: string = '';

  public cashPlanModelListTitle: string = '';

  public showOnlyMenuIcon: boolean = false;

  public selectedLanguage: string = 'en';
  public loadingText: string = '';
  public readonly notStartedStatusId: number = 1;
  public readonly submittedStatusId: number = 3;
  public readonly approvedStatusId: number = 5;
  public readonly archivedStatusId: number = 7;
  public readonly planGeneratedStatusId: number = 6;

  // Email Pattern
  public readonly emailPattern: string = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,3}";

  // inputField Pattern
  public readonly inputFieldPattern: string = "([a-zA-Z0-9',.-]+( [a-zA-Z0-9',.-]+)*)";

  public readonly monthList: any[] = [{
    monthName: "Jan",
    monthCode: "01",
    monthId: 1
  },
  {
    monthName: "Feb",
    monthCode: "02",
    monthId: 2
  },
  {
    monthName: "Mar",
    monthCode: "03",
    monthId: 3
  },
  {
    monthName: "Apr",
    monthCode: "04",
    monthId: 4
  },
  {
    monthName: "May",
    monthCode: "05",
    monthId: 5
  },
  {
    monthName: "Jun",
    monthCode: "06",
    monthId: 6
  },
  {
    monthName: "Jul",
    monthCode: "07",
    monthId: 7
  },
  {
    monthName: "Aug",
    monthCode: "08",
    monthId: 8
  },
  {
    monthName: "Sep",
    monthCode: "09",
    monthId: 9
  },
  {
    monthName: "Oct",
    monthCode: "10",
    monthId: 10
  },
  {
    monthName: "Nov",
    monthCode: "11",
    monthId: 11
  },
  {
    monthName: "Dec",
    monthCode: "12",
    monthId: 12
  },
  ];
  //Collapse and expend left menu
  public isNeedToCollapsedMenu: boolean = false;
  private i: number = 0;
  collapseMenu() {
    if (this.i == 0) {
      this.i = 1;
      this.isNeedToCollapsedMenu = true;
    }
    else {
      this.isNeedToCollapsedMenu = false;
      this.i = 0;
    }

  }
}



