export const environment = {
  production: true,

  // Baryons Dev
  // BaseURL : "http://cashflow.baryons.net:9090/api/"

  // Baryons QA
  // BaseURL : "http://cashflowqa.baryons.net:9091/api/"

  // Client Dev
  // BaseURL: "http://192.168.100.4:9090/api/"

  // Client UAT
  //BaseURL: "http://cashmanagementapi.oags.so:83/api/"
  BaseURL: "http://69.16.204.28:83/api/"
  


  // BaseURL : "http://localhost:27278/api/"
  // BaseURL : "http://192.168.1.64:44311//api/"
  
};
