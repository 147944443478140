import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'usermanagement/login', pathMatch: 'full' },
  { path: 'usermanagement/login', loadChildren: () => import('./pages/usermanagement/login/login.module').then(m => m.LoginModule) },
  { path: 'common/dashboard', loadChildren: () => import('./pages/common/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'usermanagement/changepassword', loadChildren: () => import('./pages/usermanagement/changepassword/changepassword.module').then(m => m.ChangepasswordModule) },
  { path: 'utility/password-strength-meter', loadChildren: () => import('./pages/utility/password-strength-meter/password-strength-meter.module').then(m => m.PasswordStrengthMeterModule) },
  { path: 'common/menu', loadChildren: () => import('./pages/common/menu/menu.module').then(m => m.MenuModule) },
  { path: 'common/header', loadChildren: () => import('./pages/common/header/header.module').then(m => m.HeaderModule) },
  { path: 'expense/expenselist', loadChildren: () => import('./pages/expense/expenselist/expenselist.module').then(m => m.ExpenselistModule) },
  { path: 'usermanagement/rolepermissionlist', loadChildren: () => import('./pages/usermanagement/rolepermissionlist/rolepermissionlist.module').then(m => m.RolepermissionlistModule) },
  { path: 'usermanagement/assignpermissiontorole', loadChildren: () => import('./pages/usermanagement/assignpermissiontorole/assignpermissiontorole.module').then(m => m.AssignpermissiontoroleModule) },
  { path: 'revenue/adjustmentrevenuelist', loadChildren: () => import('./pages/revenue/adjustmentrevenuelist/adjustmentrevenuelist.module').then(m => m.AdjustmentrevenuelistModule) },
  { path: 'revenue/updaterevenueinfo', loadChildren: () => import('./pages/revenue/updaterevenueinfo/updaterevenueinfo.module').then(m => m.UpdaterevenueinfoModule) },
  { path: 'usermanagement/userlist', loadChildren: () => import('./pages/usermanagement/userlist/userlist.module').then(m => m.UserlistModule) },
  { path: 'usermanagement/addupdateuser', loadChildren: () => import('./pages/usermanagement/addupdateuser/addupdateuser.module').then(m => m.AddupdateuserModule) },
  { path: 'usermanagement/userrolelist', loadChildren: () => import('./pages/usermanagement/userrolelist/userrolelist.module').then(m => m.UserrolelistModule) },
  { path: 'usermanagement/addupdateuserrole', loadChildren: () => import('./pages/usermanagement/addupdateuserrole/addupdateuserrole.module').then(m => m.AddupdateuserroleModule) },
  { path: 'chartofaccount/fundlist', loadChildren: () => import('./pages/chartofaccount/fundlist/fundlist.module').then(m => m.FundlistModule) },
  { path: 'chartofaccount/objectlist', loadChildren: () => import('./pages/chartofaccount/objectlist/objectlist.module').then(m => m.ObjectlistModule) },
  { path: 'chartofaccount/programlist', loadChildren: () => import('./pages/chartofaccount/programlist/programlist.module').then(m => m.ProgramlistModule) },
  { path: 'chartofaccount/locationlist', loadChildren: () => import('./pages/chartofaccount/locationlist/locationlist.module').then(m => m.LocationlistModule) },
  { path: 'usermanagement/forgotpassword', loadChildren: () => import('./pages/usermanagement/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule) },
  { path: 'chartofaccount/organizationlist', loadChildren: () => import('./pages/chartofaccount/organizationlist/organizationlist.module').then(m => m.OrganizationlistModule) },
  { path: 'revenue/revenueobjectclihistory', loadChildren: () => import('./pages/revenue/revenueobjectclihistory/revenueobjectclihistory.module').then(m => m.RevenueobjectclihistoryModule) },
  { path: 'usermanagement/departmentlist', loadChildren: () => import('./pages/usermanagement/departmentlist/departmentlist.module').then(m => m.DepartmentlistModule) },
  { path: 'revenue/revenuecashplanview', loadChildren: () => import('./pages/revenue/revenuecashplanview/revenuecashplanview.module').then(m => m.RevenuecashplanviewModule) },
  { path: 'revenue/draftrevenuecashplanview', loadChildren: () => import('./pages/revenue/revenuecashplanview/revenuecashplanview.module').then(m => m.RevenuecashplanviewModule) },
  { path: 'sfmsis/fmsisrowbudgetlist', loadChildren: () => import('./pages/sfmsis/fmsisrowbudgetlist/fmsisrowbudgetlist.module').then(m => m.FmsisrowbudgetlistModule) },
  { path: 'sfmsis/fmsisrowbankbalancelist', loadChildren: () => import('./pages/sfmsis/fmsisrowbankbalancelist/fmsisrowbankbalancelist.module').then(m => m.FmsisrowbankbalancelistModule) },
  { path: 'sfmsis/fmsisrowwarrentlist', loadChildren: () => import('./pages/sfmsis/fmsisrowwarrentlist/fmsisrowwarrentlist.module').then(m => m.FmsisrowwarrentlistModule) },
  { path: 'sfmsis/fmsisrowpolist', loadChildren: () => import('./pages/sfmsis/fmsisrowpolist/fmsisrowpolist.module').then(m => m.FmsisrowpolistModule) },
  { path: 'expense/expenseobjectclihistory', loadChildren: () => import('./pages/expense/expenseobjectclihistory/expenseobjectclihistory.module').then(m => m.ExpenseobjectclihistoryModule) },
  { path: 'expense/expensecashplanview', loadChildren: () => import('./pages/expense/expensecashplanview/expensecashplanview.module').then(m => m.ExpensecashplanviewModule) },
  { path: 'expense/draftexpensecashplanview', loadChildren: () => import('./pages/expense/expensecashplanview/expensecashplanview.module').then(m => m.ExpensecashplanviewModule) },
  { path: 'sfmsis/fmsisrowjvlist', loadChildren: () => import('./pages/sfmsis/fmsisrowjvlist/fmsisrowjvlist.module').then(m => m.FmsisrowjvlistModule) },
  { path: 'usermanagement/addupdatedepartment', loadChildren: () => import('./pages/usermanagement/addupdatedepartment/addupdatedepartment.module').then(m => m.AddupdatedepartmentModule) },
  { path: 'revenue/revenuecashplanlist', loadChildren: () => import('./pages/revenue/revenuecashplanlist/revenuecashplanlist.module').then(m => m.RevenuecashplanlistModule) },
  { path: 'revenue/consolidatedcashplanlist', loadChildren: () => import('./pages/cashplan/consolidatedcashplanlist/consolidatedcashplanlist.module').then(m => m.ConsolidatedcashplanlistModule) },
  { path: 'revenue/consolidatedcashplanview', loadChildren: () => import('./pages/cashplan/consolidatedcashplanview/consolidatedcashplanview.module').then(m => m.ConsolidatedcashplanviewModule) },
  { path: 'revenue/consolatedreportapprovvedorreject', loadChildren: () => import('./pages/revenue/consolatedreportapprovvedorreject/consolatedreportapprovvedorreject.module').then(m => m.ConsolatedreportapprovvedorrejectModule) },
  { path: 'cashplan/annualcashplanlist', loadChildren: () => import('./pages/cashplan/annualcashplanlist/annualcashplanlist.module').then(m => m.AnnualcashplanlistModule) },
  { path: 'cashplan/cashplanmodellist', loadChildren: () => import('./pages/cashplan/cashplanmodellist/cashplanmodellist.module').then(m => m.CashplanmodellistModule) },
  { path: 'cashplan/revenuecashplanmodel', loadChildren: () => import('./pages/cashplan/revenuecashplanmodel/revenuecashplanmodel.module').then(m => m.RevenuecashplanmodelModule) },
  { path: 'report/allotmentallocationcomparison', loadChildren: () => import('./pages/report/allotmentallocationcomparison/allotmentallocationcomparison.module').then(m => m.AllotmentallocationcomparisonModule) },
  { path: 'report/revenuepatternnanalysis', loadChildren: () => import('./pages/report/revenuepatternnanalysis/revenuepatternnanalysis.module').then(m => m.RevenuepatternnanalysisModule) },
  { path: 'report/trendanalysis', loadChildren: () => import('./pages/report/trendanalysis/trendanalysis.module').then(m => m.TrendanalysisModule) },
  { path: 'report/defaultpatternanalysisreport', loadChildren: () => import('./pages/report/defaultpatternanalysisreport/defaultpatternanalysisreport.module').then(m => m.DefaultpatternanalysisreportModule) },
  { path: 'revenue/cashplanreport', loadChildren: () => import('./pages/revenue/cashplanreport/cashplanreport.module').then(m => m.CashplanreportModule) },
  { path: 'cashplan/generateconsolidatedcashplan', loadChildren: () => import('./pages/cashplan/generateconsolidatedcashplan/generateconsolidatedcashplan.module').then(m => m.GenerateconsolidatedcashplanModule) },
  { path: 'report/consolidatedtcashplanreport', loadChildren: () => import('./pages/report/consolidatedtcashplanreport/consolidatedtcashplanreport.module').then(m => m.ConsolidatedtcashplanreportModule) },
  { path: 'expense/expensecashplanlist', loadChildren: () => import('./pages/expense/expensecashplanlist/expensecashplanlist.module').then(m => m.ExpensecashplanlistModule) },
  { path: 'report/expensetrendanalysis', loadChildren: () => import('./pages/report/expensetrendanalysis/expensetrendanalysis.module').then(m => m.ExpensetrendanalysisModule) },
  { path: 'report/actualallotment', loadChildren: () => import('./pages/report/actualallotment/actualallotment.module').then(m => m.ActualallotmentModule) },
  { path: 'report/expensecashplanreport', loadChildren: () => import('./pages/report/expensecashplanreport/expensecashplanreport.module').then(m => m.ExpensecashplanreportModule) },
  { path: 'revenue/adjustmentrevenuehistorylist', loadChildren: () => import('./pages/revenue/adjustmentrevenuehistorylist/adjustmentrevenuehistorylist.module').then(m => m.AdjustmentrevenuehistorylistModule) },
  { path: 'report/rfarcomparison', loadChildren: () => import('./pages/report/rfarcomparison/rfarcomparison.module').then(m => m.RfarcomparisonModule) },
  { path: 'sfmsis/fmsisrowaudithistorylist', loadChildren: () => import('./pages/sfmsis/fmsisrowaudithistorylist/fmsisrowaudithistorylist.module').then(m => m.FmsisrowaudithistorylistModule) },
  { path: 'report/defaultpatternanalysis', loadChildren: () => import('./pages/report/defaultpatternanalysis/defaultpatternanalysis.module').then(m => m.DefaultpatternanalysisModule) },
  { path: 'usermanagement/resetpassword/:token/:userId', loadChildren: () => import('./pages/usermanagement/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
  { path: 'usermanagement/myaccount', loadChildren: () => import('./pages/usermanagement/myaccount/myaccount.module').then(m => m.MyaccountModule) },
  { path: 'sfmsis/fmsisrowallotmentlist', loadChildren: () => import('./pages/sfmsis/fmsisrowallotmentlist/fmsisrowallotmentlist.module').then(m => m.FmsisrowallotmentlistModule) },
  { path: 'sfmsis/fmsisrowcoalist', loadChildren: () => import('./pages/sfmsis/fmsisrowcoalist/fmsisrowcoalist.module').then(m => m.FmsisrowcoalistModule) },
  { path: 'sfmsis/fmsisrowcoatreelist', loadChildren: () => import('./pages/sfmsis/fmsisrowcoatreelist/fmsisrowcoatreelist.module').then(m => m.FmsisrowcoatreelistModule) },
  { path: 'expense/annualexpensecashplanlist', loadChildren: () => import('./pages/expense/annualexpensecashplanlist/annualexpensecashplanlist.module').then(m => m.AnnualexpensecashplanlistModule) },
  { path: 'common/configurationmanager', loadChildren: () => import('./pages/common/configurationmanager/configurationmanager.module').then(m => m.ConfigurationmanagerModule) },
  { path: 'expense/expensemodellist', loadChildren: () => import('./pages/expense/expensemodellist/expensemodellist.module').then(m => m.ExpensemodellistModule) },
  { path: 'expense/createexpensemodel', loadChildren: () => import('./pages/expense/createexpensemodel/createexpensemodel.module').then(m => m.CreateexpensemodelModule) },
  { path: 'setting/sfmismanualrefresh', loadChildren: () => import('./pages/setting/sfmismanualrefresh/sfmismanualrefresh.module').then(m => m.SfmismanualrefreshModule) },
  { path: 'expense/expenseadjustment', loadChildren: () => import('./pages/expense/expenseadjustment/expenseadjustment.module').then(m => m.ExpenseadjustmentModule) },
  { path: 'revenue/createrevenuemodel', loadChildren: () => import('./pages/revenue/createrevenuemodel/createrevenuemodel.module').then(m => m.CreaterevenuemodelModule) },
  { path: 'setting/commisionconfig', loadChildren: () => import('./pages/setting/setting/commisionconfig/commisionconfig.module').then(m => m.CommisionconfigModule) },
  { path: 'setting/sfmismanualdatapush', loadChildren: () => import('./pages/setting/sfmismanualdatapush/sfmismanualdatapush.module').then(m => m.SfmismanualdatapushModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
