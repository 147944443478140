/**
 * This file is use to show confirmation popup.
 */

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmdialogComponent implements OnInit {

  showButton: boolean = true;
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  //It will be automatically invoked when an instance of the class is created
  constructor(private activeModal: NgbActiveModal) { }

  //Use the constructor to initialize class members
  ngOnInit() {
    console.log("btnCancelText ", this.btnCancelText);
  }

  //to decline
  public decline() {
    this.activeModal.close(false);
  }

  //to accept
  public accept() {
    this.activeModal.close(true);
  }

  //to dismiss
  public dismiss() {
    this.activeModal.dismiss();
  }

}
