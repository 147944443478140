import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CashFlowUI';

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private apiCall: ApiService
  ) {
    const selectedLanguage = this.common.getValueFromLocalStorage("selectedLanguage");
    if (selectedLanguage == 'so') {
      this.common.selectedLanguage = 'so';
      this.translate.setDefaultLang("so");
    } else {
      this.translate.setDefaultLang("en");
    }
  }
}
