import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmdialogComponent } from './confirmdialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmdialogComponent, { size: dialogSize, centered: true, backdrop: 'static', keyboard: false, windowClass: 'custom-delete-modal' });
    modalRef.componentInstance.title = title;
    // to show message
    modalRef.componentInstance.message = message;
    // to show ok button
    modalRef.componentInstance.btnOkText = btnOkText;
    // to show cancel button
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
