import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) { }

  //Post response  or push data by Post method
  async postAPICall(APIEndpoint: string, formData: any, hideLoader: boolean = true): Promise<any> {
    /** spinner starts on init */
    await this.spinner.show();
    //Creates a new Promise.
    return new Promise<any>((resolve, reject) => {
      //Constructs a `POST` request that interprets the body as a JSON object
      this.httpClient.post(environment.BaseURL + APIEndpoint, formData).subscribe(res => {
        console.log("APIEndpoint", APIEndpoint, " formData ", formData, " result ", res);
        if (hideLoader) {
          this.spinner.hide();
        }
        resolve(res);
      }, err => {
        console.log("APIEndpoint", APIEndpoint, " formData ", formData, " Error ", err);
        if (err.status == 0) {
          const obj: any = {
            closeButton: true,
            timeOut: 3000,
            progressBar: true   ,
            positionClass: 'toast-top-full-width'     
          }
          this.toastr.error('', 'Something went wrong. Please try again Later' , obj);
        }
        this.spinner.hide();
        reject(err);

      }
      );
    })
  }

  async getAPICall(APIEndpoint: string, formData: any, hideLoader: boolean = true): Promise<any> {
    /** spinner starts on init */
    await this.spinner.show();
    //Creates a new Promise.
    return new Promise<any>((resolve, reject) => {
      //Constructs a `POST` request that interprets the body as a JSON object
      this.httpClient.get(environment.BaseURL + APIEndpoint, formData).subscribe(res => {
        //console.log("APIEndpoint", APIEndpoint, " formData ", formData, " result ", res);
        if (hideLoader) {
          this.spinner.hide();
        }
        resolve(res);
      }, err => {
        console.log("APIEndpoint", APIEndpoint, " formData ", formData, " Error ", err);
        if (err.status == 0) {
          const obj: any = {
            closeButton: true,
            timeOut: 3000,
            progressBar: true   ,
            positionClass: 'toast-top-full-width'     
          }
          this.toastr.error('', 'Something went wrong. Please try again Later' , obj);
        }
        this.spinner.hide();
        reject(err);

      }
      );
    })
  }


  //Post response  or push data by Post method
  async postAPICallwithToken(APIEndpoint: string, formData: any, token: string): Promise<any> {
    /** spinner starts on init */
    await this.spinner.show();
    //Creates a new Promise.
    return new Promise<any>((resolve, reject) => {

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token
        })
      };

      //Constructs a `POST` request that interprets the body as a JSON object
      this.httpClient.post(environment.BaseURL + APIEndpoint, formData, httpOptions).subscribe(res => {
        //console.log("APIEndpoint", APIEndpoint, " formData ", formData, " result ", res);

        this.spinner.hide();

        resolve(res);
      }, err => {
        console.log("APIEndpoint", APIEndpoint, " formData ", formData, " Error ", err);
        if (err.status == 0) {

        }
        this.spinner.hide();
        reject(err);

      }
      );
    })
  }

}
